<template>
  <el-dialog
    :visible="visible"
    append-to-body
    width="468px"
    custom-class="launch-live-dialog"
    title="提示"
    @close="handleClose"
  >
    <div class="content-wrapper">
      发起Live功能仅针对鲸准已认证身份的用户开放使用，请您完成相关身份认证后发起直播。
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="mini"
        @click="handleClose"
      >
        取消
      </el-button>
      <el-button
        type="primary"
        size="mini"
        @click="certifyLive"
      >
        去认证
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getCloudToken } from '@/services/home';
import { environmentHref, cloudRequestPath } from '@/utils/cloudRequest';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 认证创业者身份
    async certifyLive() {
      this.handleClose();
      try {
        const token = await getCloudToken();
        const fullPath = `${environmentHref}/#/financing/personalCenter`;
        window.open(`${cloudRequestPath}&token=${token}&redirectUrl=${fullPath}`);
      } catch (error) {
        // console.log(error)
      }
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.content-wrapper {
  padding: 22px 0;
  line-height: 20px;
}
</style>
